<template>
  <div class="reset-password-form">
    <div class="on-success" v-if="success">
      <div class="text-h5 mb-4">Verifique seu e-mail</div>
      <v-alert dense text color="success">
        Nós enviamos um e-mail para <strong>{{ email_tip }}</strong
        >. Clique no link do e-mail para alterar sua senha.<br />
        Se o e-mail estiver incorreto entre em contato conosco.
      </v-alert>
    </div>
    <v-form @submit.prevent="send" v-else>
      <div class="text-h5">Entre com suas informações pessoais</div>
      <v-radio-group v-model="form.kind" row>
        <template v-slot:label>
          <strong>Eu sou?</strong>
        </template>
        <v-radio label="Paciente" value="patient"></v-radio>
        <v-radio label="Médico" value="doctor"></v-radio>
        <v-radio label="Parceiro" value="partner_place"></v-radio>
      </v-radio-group>
      <v-text-field
        v-show="form.kind == 'patient'"
        label="CPF"
        autofocus
        dense
        outlined
        name="cpf"
        v-mask.raw="'###.###.###-##'"
        v-model="form.cpf"
      ></v-text-field>
      <v-text-field
        v-show="form.kind == 'doctor'"
        label="CRM"
        autofocus
        dense
        outlined
        v-mask="'AA############'"
        name="crm"
        v-model="form.crm"
      ></v-text-field>
      <v-text-field
        v-show="form.kind == 'partner_place'"
        label="Login"
        autofocus
        dense
        outlined
        name="login"
        v-model="form.login"
      ></v-text-field>
      <v-btn type="submit" elevation="1" color="primary"> Pesquisar </v-btn>
      <v-btn
        type="button"
        @click.prevent="$parent.toggleShowResetPassword()"
        elevation="1"
        class="ml-4"
        color="secondary"
      >
        Cancelar
      </v-btn>
    </v-form>
  </div>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
export default {
  name: 'LoginResetPassword',
  data() {
    return {
      loading: false,
      email_tip: null,
      success: false,
      form: {
        kind: 'patient',
        cpf: null,
        crm: null,
        login: null,
      },
    }
  },

  methods: {
    send() {
      this.loading = true
      const payload = {
        kind: this.form.kind,
        cpf: this.$raw(this.form.cpf),
        crm: this.form.crm,
        login: this.form.login,
      }

      Api.resetPasswordInstructions(payload)
        .then(response => {
          this.success = true
          this.email_tip = response.data.email_tip
        })
        .catch(error => {
          if (error.response.status == 500) {
            this.$toast.show('Erro ao recuperar senha', 'error')
          } else if (error.response.status == 404) {
            this.$toast.show('Usuário não encontrado', 'error')
          } else {
            this.$toast.show(error.response.data.error, 'error')
          }
          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
.reset-password-form {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px !important;
}
</style>
