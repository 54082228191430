<template id="login">
  <v-row class="ma-0" no-gutters>
    <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5" class="login-form">
      <v-row align="center" justify="center" class="fill-height">
        <v-col>
          <v-form
            @submit.prevent="signin"
            class="text-center"
            v-show="!showResetPassword"
          >
            <img alt="Logo" class="client-logo" :src="organization.logo" />
            <div
              :class="[{ 'mx-4': $vuetify.breakpoint.mobile }, 'login-info']"
            >
              <b>Importante!</b> Para fazer o login, caso seja paciente, informe
              o seu CPF ou código informado no cartão de entrega. Caso seja
              médico solicitante, informe seu <b>CRM</b> com a
              <b>UF (Ex. SP1234).</b>
            </div>
            <div style="max-width: 330px; margin: 0 auto">
              <v-text-field
                label="Informe (Código, CPF ou CRM)"
                autofocus
                dense
                outlined
                name="username"
                v-model="credentials.username"
              ></v-text-field>
              <v-text-field
                v-model="credentials.password"
                name="password"
                dense
                ref="passwordInput"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Senha"
                outlined
                @click:append="showPassword = !showPassword"
              >
              </v-text-field>
              <div class="mb-4 text-left">
                <a
                  @click.prevent="showResetPassword = true"
                  class="mb-4 font-weight-medium"
                >
                  Esqueceu sua senha?
                </a>
              </div>

              <v-btn
                block
                type="submit"
                :loading="loading"
                elevation="1"
                color="primary"
              >
                Continuar
              </v-btn>
              <img
                alt="Logo"
                class="naja-logo"
                src="@/assets/naja_logo_login.png"
              />
            </div>
          </v-form>
          <ResetPassword ref="resetPassword" v-show="showResetPassword" />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="!$vuetify.breakpoint.mobile"
      class="custom-client-bg"
      v-html="organization.signin_html"
    ></v-col>
  </v-row>
</template>
<script type="text/javascript">
import Api from '@/resources/NajaSocialApi'
import ResetPassword from './widgets/reset_password.vue'
import { mapState } from 'vuex'
export default {
  name: 'login',
  components: { ResetPassword },
  computed: {
    ...mapState({
      organization: state => state.data.organization,
    }),
  },

  data() {
    return {
      loading: false,
      showPassword: false,
      showResetPassword: false,
      credentials: {
        username: '',
        password: '',
      },
    }
  },

  mounted() {
    if (this.$route.query.username) {
      this.credentials.username = this.$route.query.username
      this.$refs.passwordInput.focus()
    }
  },

  methods: {
    toggleShowResetPassword() {
      this.showResetPassword = !this.showResetPassword
      // if (this.showResetPassword) {
      //   this.$refs.resetPassword.focus()
      // }
    },

    signin() {
      this.loading = true

      Api.authenticate(this.credentials)
        .then(async response => {
          this.$store.commit('updateUser', response.data)
          await this.$store.dispatch('getUserInfo')

          if (this.$store.state.data.user.role == 'patient') {
            this.$router.push({ name: 'home' })
          } else {
            this.$router.push({ name: 'exams' })
          }
        })
        .catch(() => {
          this.$toast.show('Login inválido', 'error')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
.login-form {
  form {
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;
  }

  form.reset-password-form {
    max-width: 400px;
  }
}

.login-info {
  text-align: left;
  border-radius: 1rem;
  border: 1px solid #f58220;
  background: #fceccd;
  padding: 1rem;
  color: #585348;
  margin-bottom: 3rem;
  font-size: 0.875rem;
}

.custom-client-bg {
  min-height: 100vh;
}

.naja-logo {
  height: 3rem;
  margin: 0 auto;
  display: block;
  margin-top: 6rem;
}

.client-logo {
  max-height: 5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
</style>
